<template>
  <component :is="layout">
    <DarkMode class="darkmode noselect"></Darkmode>
    <router-view :layout.sync="layout"/>
  </component>
</template>

<script>
import DarkMode from "vue-dark-mode-switcher";
export default {
  name: 'App',
  components: {
    DarkMode,
  },
  data() {
    return {
      layout: 'div',
    };
  },
};
</script>

<style>
.theme-dark{
    background: #292929;
    color: white;
}

.darkmode b {
  font-size: 1.4em;
}

.darkmode:hover {
  text-decoration: none;
}
</style>
