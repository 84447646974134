import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/components/Home';
import Documentation from '@/components/Documentation';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/documentation',
      name: 'Documentation',
      component: Documentation,
      meta: {
        title: 'Tsuginoji API',
      },
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: 'Tsuginoji',
      },
    },
    {
      path: '/:word',
      name: 'Search',
      component: Home,
      meta: {
        title: 'Tsuginoji',
      },
    },
  ],
});


export default router;
