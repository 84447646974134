<template>
  <div class="home">
    <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3"></div>
        <div class="col-lg">
          <h3 class="my-3 noselect hoverable" @click="redirect()" unselectable="on">Tsuginoji 次の字</h3>
          <div>
             <h5>GET /api/words/:word?page=number&amp;limit=number</h5>
             <p>
                Tsuginoji is built RESTfully and uses a MongoDB backend. All definition and pitch accent information come from the 三省堂スーパー大辞林 dictionary.
             </p>
             <h5>Endpoint URL</h5>
             <pre :class="{'theme-dark': !isLightmode}">https://www.tsuginoji.com/api/words/:word?page=number&amp;limit=number</pre>
             <p>
                 To use the API, you must enter a <b>word</b>. However, both <b>page</b> and <b>limit</b> are optional (default: page=1, limit=20). Because of how many entries a single search can show, the backend uses <a href="https://www.npmjs.com/package/mongoose-aggregate-paginate-v2" target="_blank">mongoose-aggregate-paginate-v2</a>.
                 The limit would be the maximum number of entries you want returned. For example, if I wanted only 20 results when I search the word 火, I would set the limit to 20. If there are a total of 40 results, then page 1 would contain the first 20 entries and page 2 would contain the remaining 20 entries.
             </p>
             <h5>Example requests</h5>
             <pre :class="{'theme-dark': !isLightmode}">curl https://www.tsuginoji.com/api/words/火</pre>
             <pre :class="{'theme-dark': !isLightmode}">curl https://www.tsuginoji.com/api/words/火?page=1&amp;limit=2</pre>
             <h5>Example Response</h5>
             <p>
                 The above requests would output an array of word objects. If the searched word does not exist in the database, the API will return a status code of 404 as well as an empty array. To see examples, please refer to <a href="https://www.tsuginoji.com/api/words/火" target="_blank">example 1</a>, <a href="https://www.tsuginoji.com/api/words/hi?page=1&limit=2" target="_blank">example 2</a>, and <a href="https://www.tsuginoji.com/api/words/404?page=1&limit=2" target="_blank">404</a>.
             </p>
             <h5>Response Fields</h5>
             <table class="table" :class="{'theme-dark': !isLightmode}">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <th scope="row">_id</th> 
                    <td>string</td>
                    <td>Unique identifier of this word.</td>
                    </tr>
                    <tr>
                    <th scope="row">pitch</th>
                    <td>array</td>
                    <td>This returns an array of the word's pitch accent. [0] represents 平板, [1] represents 頭高, [2+] represents 中高/尾高, depending on the number of moras the word has. For more information, please refer to this <a href="https://imgur.com/oEjxhWU" target="_blank">graph</a>.</td>
                    </tr>
                    <tr>
                    <th scope="row">word</th>
                    <td>string</td>
                    <td>The searched word. Can be in hiragana, katakana, or kanji.</td>
                    </tr>
                    <tr>
                    <th scope="row">kana</th>
                    <td>string</td>
                    <td>The kana for the word. Can be in hiragana or katakana. For kana only words, this will be the same as the <b>word</b> field.</td>
                    </tr>
                    <tr>
                    <th scope="row">definition</th>
                    <td>string</td>
                    <td>The definition for the word.</td>
                    </tr>
                    <tr>
                    <th scope="row">audio</th>
                    <td>string</td>
                    <td>The audio for the word. If there is no audio, returns "". Please note that all audio links are http (you can change it to https on the client side through string manipulation).</td>
                    </tr>
                    <tr>
                    <th scope="row">kanaLength</th>
                    <td>int</td>
                    <td>The string length of the <b>kana</b> field. This can be useful for creating pitch accent graphs.</td>
                    </tr>
                    <tr>
                    <th scope="row">wordLength</th>
                    <td>int</td>
                    <td>The string length of the <b>word</b> field.</td>
                    </tr>
                    <tr>
                    <th scope="row">definitionLength</th>
                    <td>int</td>
                    <td>The string length of the <b>definition</b> field.</td>
                    </tr>
                </tbody>
                </table>
          </div>
        </div>
        <div class="col-lg-3"></div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    name: 'Documentation',
    methods: {
        redirect() {
            this.$router.push('/').catch((err) => {console.log(err)});
        }
    },
    data() { 
        return {
            isLightmode: document.getElementsByTagName('body')[0].classList[0] != 'theme-dark'
        }
    }, 
    mounted() {
        let self = this; 

        document.getElementsByClassName("darkmode")[0].addEventListener('click', () => {
            self.isLightmode = !self.isLightmode
        })
    }
};
</script>

<style scoped>
@import '../assets/css/styles.css';

h5 {
    display: block;
    font-size: 1.05em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
}
pre {
    font-size: 1em;
}

.darkmode {
    color: white;
}


</style>