import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import router from './router/routes';
import Chartkick from 'vue-chartkick'
import Chart from 'chart.js'
import infiniteScroll from 'vue-infinite-scroll'
import { ToggleButton } from 'vue-js-toggle-button'

Vue.use(infiniteScroll)
Vue.component('ToggleButton', ToggleButton)
Vue.use(Chartkick.use(Chart))

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  components: { App },
  template: '<App/>',
  render: h => h(App)
});
