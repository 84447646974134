<template>
  <div class="home">
    <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3"></div>
        <div class="col-lg">
          <h3 class="my-3 noselect hoverable" @click="clear()" unselectable="on">Tsuginoji 次の字</h3>
          <div class="input-group mb-3">
            <input type="text" ref="search" autocomplete="on" class="form-control" placeholder="漢字・仮名・ローマ字" aria-label="search" aria-describedby="basic-addon2" v-model="searchWord" @keyup.enter="redirectPage" @focus="$event.target.select()">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary" type="button" @click="redirectPage">項目を検索</button>
            </div>
          </div>
          <div v-show="sentence.length > 0" transition="expand">
            <transition-group name="slide-fade" mode="out-in">
            <div v-for="(word, index) in sentence" :key=index class="wordBlock mr-2">
              {{word}}
            </div>
            </transition-group>
            <button type="button" class="close" aria-label="Close" v-show="sentence" @click="sentence = []">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
              <toggle-button class="toggle-button" v-model="autoSearch" :sync="true" v-show="!(screenWidth <= 560)"
          :labels="{checked: '自動検索', unchecked: '自動検索'}" :width="75" :class="{ 'mt-2': sentence }"/>         
          <div v-show="words.length > 0 && errors.length == 0" v-infinite-scroll="loadMore"
              infinite-scroll-disabled="busy"
              infinite-scroll-distance="500"
              class="mt-2">
            <transition-group name="slide-fade" mode="out-in">
            <div v-for="word in words"
                  :key="word._id"
                  :id="word._id">
              <div class="my-4 word">
                <div class="wordBlock mr-2">
                <h2 class="word-title">{{word.word}}</h2>
                <svg @click.prevent="playSound(word.audio)" class="hoverable" v-if="word.audio" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
                </div>
                <p v-html="formatDefinition(word.definition)" class="definition"></p>
                <div v-if="word.pitch">
                  <div v-for="pitch in word.pitch" :key="pitch" class="wordBlock mr-5">
                    <line-chart :legend="false" label="Value" :data="createAccent(word.kana, pitch)" :library="chartOptions" :style="styleChart(word.kana)" :colors="['#b00']"></line-chart>
                    <p>{{pitch}}</p>
                  </div>
                </div>
              </div>
            </div>
            </transition-group>        
          </div>
          <transition name="slide-fade" mode="out-in">
          <div v-show="words.length == 0 && $route.name == 'Home'">
            <ul>
              <li>「Ｆ」を押すと入力</li>
              <li>「Ｄ」を押すと自動検索のオンオフ切り替えができます</li>
              <li>自動検索がオフになっている場合、単語や文章をセレクトして「Ｓ」を押すとその単語を調べられます</li>
              <li>ＡＰＩの使い方は<b><router-link to="documentation">こちら</router-link></b>（英語で書いてあります）</li>
              <li>このサイトを気に入ったら<b><a href='https://ko-fi.com/S6S02LEGG' target='_blank'>寄付</a></b>してくれると嬉しいです
              </li>
            </ul>
          </div>
          </transition>
          <transition name="slide-fade" mode="out-in">
          <div v-show="$route.name != 'Home' && this.errors.length > 0">
            <h3 class="word-title">４０４エラー</h3>
            <p class="definition"> その単語を見つけられませんでした。(〃∀〃)ゞ</p>
          </div>
          </transition>
        </div>
        <div class="col-lg-3"></div>
    </div>
  </div>
</div>
</template>

<script>
import axios from "axios";
import StemmerJa from "natural/lib/natural/stemmers/stemmer_ja";
import { isRomaji, isKana, toHiragana, toKatakana } from "wanakana";

export default {
  name: 'Home',
  // get client screen width to know when to hide the toggle button
  created() {
    window.addEventListener("resize", () => { this.screenWidth = document.body.clientWidth });
  },

  destroyed() {
  window.removeEventListener("resize", () => { this.screenWidth = document.body.clientWidth });
  },
  data() {
    return {
      screenWidth: screen.width,
      host: '/api/words/',
      pageNumber: 0,
      limit: 20,
      searchWord: '',
      words: [],
      errors: [],
      sentence: [],
      autoSearch: false,
      chartOptions: {
        devicePixelRatio: 4,
        responsive: true, 
        maintainAspectRatio: false,
        showTooltips: true,
        // events: [], turn off info over hover
        
        scales: {
          yAxes: [{
            display: false // this hides the y-axis in the linechart
          }],
          xAxes: [{
            display: true,
            ticks: {
              fontColor: '#26c1c9',
              userCallback: function(label) {
                return label.split('-')[0]
              },
            }
          }]
        },
        layout: {
          padding: {
            top: 5
          }
      },
    }
    }
  }, 
  watch: {
  '$route' () {
    if (this.$route.name == 'Home') { // when user uses history to go back to home
      this.words = [];
      this.sentence = [];
      this.searchWord = '';
    }
    this.search();
  },
  'autoSearch' () {
    localStorage.setItem('autoSearch', this.autoSearch); // remember user's choices
  }
},
  mounted() {
    this.focusInput();
    if (this.$route.params.word && this.words.length == 0) { // user navigated to page manually/refreshed
      this.searchWord = this.$route.params.word;
      this.search();
    }

    if (localStorage.getItem('autoSearch')) {
      this.autoSearch = (localStorage.getItem('autoSearch') == 'true');
    }

    let self = this; 
    
    window.addEventListener('keyup', function(ev) {
        if (ev.which == 70 && self.$route.name != 'Documentation') { // if user presses 'f', focus on input
          self.focusInput();
        }

        if (ev.which == 68 && ev.target.className != 'form-control' && self.$route.name != 'Documentation') { // if user presses 'd', toggle on/off autosearch button
          self.autoSearch = !self.autoSearch;
        } 

        if (ev.which == 83 && !self.autoSearch && ev.target.className != 'form-control' && window.getSelection().toString() != '' && self.$route.name != 'Documentation') { // if user presses 's' and autosearch is off, search highlighted word
          self.searchWord = window.getSelection().toString(); 
          self.$router.push(`/${encodeURIComponent(self.searchWord)}`).catch((err) => {console.log(err)});
          window.scrollTo(0,0);
        }
    });

    window.addEventListener('mouseup', function(ev) { // on select/highlight
        if(window.getSelection().toString() && ev.target.className != 'form-control' && self.autoSearch && self.$route.name != 'Documentation'){
          self.searchWord = window.getSelection().toString();  
          self.$router.push(`/${encodeURIComponent(self.searchWord)}`).catch((err) => {console.log(err)});
          window.scrollTo(0,0);
      }
    });
  },
  methods: {
    sortWords(data) { // sort words so that ones that start with the search word comes first
      const regexHiragana = new RegExp(`^${toHiragana(this.searchWord)}`);
      const regexKatakana = new RegExp(`^${toKatakana(this.searchWord)}`);
      const priority = data.filter((word) => {return (regexHiragana.test(word.word) || regexKatakana.test(word.word))});
      const notPriority = data.filter((word) => {return (regexHiragana.test(word.word) || regexKatakana.test(word.word))});
      
      // filter out any duplicates
      return priority.concat(notPriority).concat(data).filter((v,i,a)=>a.findIndex(t=>(t._id === v._id))===i);
    },
    isMobile() {
      return screen.width <= 760;
    },
    styleChart(kana) {
      let style = 'height: 60px; max-width:'
      if (kana.length <= 2) {
        return `${style} 130px`
      } else if (kana.length <= 5) {
        return `${style} 200px;`;
      } else if (kana.length < 8) {
        return `${style} 350px;`;
      } else {
        return `${style} 3000px;`;
      }
    },
     loadMore() {
      this.pageNumber++;
      this.busy = true;
      if (this.searchWord) {
        axios.get(`${this.host}${encodeURIComponent(this.searchWord)}`, { params: { page: this.pageNumber, limit: this.limit } })
        .then((res) => {
          this.words = this.words.concat(this.sortWords(res.data)); 
          this.busy = false;
        }).catch((err) => {console.log(err)});
      }
    },
    createAccent(kana, accent) {
      const sokuon = ['ぁ', 'ァ', 'ぃ', 'ィ', 'ぅ', 'ゥ', 'ぇ', 'ェ', 'ぉ', 'ォ', 'ゃ', 'ャ', 'ゅ', 'ュ', 'ょ', 'ョ'];
      let accentStr = accent.replace('[', '').replace(']', '');
      let kanaArr = kana.split('');
      let xAxisLabels = []
      for (let i =0; i < kanaArr.length; i++) { // create x axis labels, combining any sokuon that was separated from .split('')
        if (!sokuon.includes(kanaArr[i])) { // not sokuon
          xAxisLabels.push(kanaArr[i])
        } else { // combine the sokuon with the previous element
          xAxisLabels[xAxisLabels.length - 1] = xAxisLabels[xAxisLabels.length - 1] + kanaArr[i]; 
        }
      }

      let xAxisArr = []
      if (accentStr == 0 || accentStr != 1) { //draw everything heiban, even nakadaka and odaka words
        for (let i = 0; i < xAxisLabels.length; i++) {
          if (i == 0) {
            xAxisArr.push([`${xAxisLabels[i]}-${i+1}`, 0])
          } else {
            xAxisArr.push([`${xAxisLabels[i]}-${i+1}`, 1])
          }
        }
        xAxisArr.push(['(助詞)', 1]) // add particle at the end
        
        if (accentStr != 0) { // if not heiban (nakadaka, odaka), make other pitch at and after accent low
          if (xAxisArr[parseInt(accentStr)]) {
            for (let i = parseInt(accentStr); i < xAxisArr.length; i++) {
              xAxisArr[i][1] = 0;
            }
          }
        }
      } else if (accentStr == 1) { // atamadaka
          for (let i = 0; i < xAxisLabels.length; i++) {
          if (i == 0) {
            xAxisArr.push([`${xAxisLabels[i]}-${i+1}`, 1])
          } else {
            xAxisArr.push([`${xAxisLabels[i]}-${i+1}`, 0])
          }
        }
        xAxisArr.push(['(助詞)', 0])
      }
      return xAxisArr
    },
    playSound (sound) {
      if(sound) {
        let http = sound.split('http://')[1];
        const audio = new Audio(`https://${http}`);
        audio.play();       
      }
    },
    search() {
      this.errors = [];
      this.pageNumber = 0; // reset page number on search
      if (this.pageNumber == 0) this.pageNumber++;

      if (this.$route.params.word) {
          this.searchWord = this.$route.params.word;
      }

            let token = StemmerJa.prototype.tokenizeAndStem(this.searchWord, true);
            let toSearch = '';

            if (this.searchWord) {
              if (!isRomaji(this.searchWord) && !isKana(this.searchWord) && token.length >= 2) { // check if input is a sentence
              this.sentence = token;
              toSearch = token[0];
            } else {
              toSearch = this.searchWord;
            }
            axios.get(`${this.host}${encodeURIComponent(toSearch)}`, { params: { page: this.pageNumber, limit: this.limit } })
                  .then((res) => {
                    if (res.data.length > 0 ) {
                      this.words = this.sortWords(res.data);
                    } 
              }).catch(() => {
                 this.errors.push('404');
              })
            }
    },
    redirectPage() {
      this.$router.push(`/${encodeURIComponent(this.searchWord)}`).catch((err) => {console.log(err)});
    },
    clear() {
      this.$router.push('/').catch((err) => {console.log(err)});
      this.words = [];
      this.sentence = [];
      this.searchWord = '';
      this.focusInput();
    },
    focusInput(){
      this.$refs.search.focus();
    },
    formatDefinition(def) {
      let reg = /(\uFF08[\uFF10-\uFF19]+\uFF09)(?!(.(?!{))*})(?!(.(?!〔))*〕)/g // gets the japanese numbers and ignores brackets
      const numArr = def.match(reg);
      let finalDef = '';
      if (numArr) {
        finalDef = def.replace(reg, '<br/> $&'); // add a line break at where the definition listings occur
      } else {
        finalDef = def;
      }
      return finalDef;
    }
  }
};
</script>

<style scoped>
@import '../assets/css/styles.css';

 .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>